export const MACHINE_CONFIG: any = {
    id: "traffic-light",
    initial: "idle",
    context: {
        green: {
            light: "green",
            type: "3-dots",
        },
        yellow: {
            light: "yellow",
            type: "5-dots",
        },
        orange: {
            light: "orange",
            type: "4-dots",
        },
        red: {
            light: "red",
            type: "10-dots",
        },
    },
    states: {
        idle: {
            on: {
                TRIGGER_GREEN: { target: "green" },
            },
        },
        green: {
            invoke: {
                id: "invokeGreen",
                src: "greenService",
                onDone: {
                    target: "orange",
                    actions: ["successAction"],
                },
                onError: {
                    target: "end",
                    actions: ["failureAction"],
                },
            },
        },
        orange: {
            invoke: {
                id: "invokeOrange",
                src: "orangeService",
                onDone: {
                    target: "yellow",
                    actions: ["successAction"],
                },
                onError: {
                    target: "end",
                    actions: ["failureAction"],
                },
            },
        },
        yellow: {
            invoke: {
                id: "invokeYellow",
                src: "yellowService",
                onDone: {
                    target: "red",
                    actions: ["successAction"],
                },
                onError: {
                    target: "end",
                    actions: ["failureAction"],
                },
            },
        },
        red: {
            invoke: {
                id: "invokeRed",
                src: "redService",
                onDone: {
                    target: "end",
                    actions: ["successAction"],
                },
                onError: {
                    target: "end",
                    actions: ["failureAction"],
                },
            },
        },
        end: {
            type: "final",
            invoke: {
                id: "invokeEnd",
                src: "endService",
                onDone: {
                    actions: ["successAction"],
                },
                onError: {
                    actions: ["failureAction"],
                },
            },
        },
    },
};
