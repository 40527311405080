import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { DATA } from "./config/asset.data";

const MODULE_NAME = "AssetService";
const log: any = LoggerModule.getInstance();

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export class AssetService {
    private static instance: AssetService;

    private constructor(props: any = {}) {}
    public static getInstance(props: any = {}): AssetService {
        if (!AssetService.instance) {
            AssetService.instance = new AssetService(props);
        }
        return AssetService.instance;
    }

    async getAsset(assetId: string, assetGroup: string) {
        const _found = _.find(DATA, {
            assetId: assetId,
            assetGroup: assetGroup,
        });
        return _found;
    }
}
