export const DEFAULT_BLUEPRINT_CONFIG = {
    walletConnect: {
        relayURL: "https://walletbridge.vlinder.io/",
    },
    servers: {
        graphql: {
            appUrl: "https://staging-nft-webgateway.vlinder.io",
        },
    },
};
