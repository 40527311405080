export const MACHINE_SERVICES = {
    greenService: (context: any, event: any) => {
        return new Promise(async (resolve, reject) => {
            console.log("[greenService][context]", context);
            try {
                const res = { key: "green", value: `Hello from greenService` };
                resolve(res);
            } catch (err) {
                reject(err.message);
            }
        });
    },
    orangeService: (context: any, event: any) => {
        console.log("[orangeService][context]", context);
        return new Promise(async (resolve, reject) => {
            try {
                const res = {
                    key: "orange",
                    value: `Hello from orangeService`,
                };
                resolve(res);
            } catch (err) {
                reject(err.message);
            }
        });
    },
    yellowService: (context: any, event: any) => {
        console.log("[yellowService][context]", context);
        return new Promise(async (resolve, reject) => {
            try {
                const res = {
                    key: "yellow",
                    value: `Hello from yellowService`,
                };
                resolve(res);
            } catch (err) {
                reject(err.message);
            }
        });
    },
    redService: (context: any, event: any) => {
        console.log("[redService][context]", context);
        return new Promise(async (resolve, reject) => {
            try {
                const res = { key: "red", value: `Hello from redService` };
                resolve(res);
            } catch (err) {
                reject(err.message);
            }
        });
    },
    endService: (context: any, event: any) => {
        console.log("[endService][context]", context);
        return new Promise(async (resolve, reject) => {
            try {
                const res = { key: "end", value: `Hello from endService` };
                resolve(res);
            } catch (err) {
                reject(err.message);
            }
        });
    },
};
