import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../../../application";
import { GET_ASSET_DETAIL } from "../graphql/asset-detail.query";
import { GraphqlServer } from "../../../servers/graphql.server";

const MODULE_NAME = "AssetService";
const log: any = LoggerModule.getInstance();

export class AssetDetailService {
    private static instance: AssetDetailService;
    private vapp: VApplication;
    private constructor(props: any = {}) {
        this.vapp = VApplication.getInstance();
    }
    public static getInstance(props: any = {}): AssetDetailService {
        if (!AssetDetailService.instance) {
            AssetDetailService.instance = new AssetDetailService(props);
        }
        return AssetDetailService.instance;
    }

    async getAsset(assetId: string, assetGroup: string) {
        const client = GraphqlServer.getInstance()?.client;
        try {
            if (client) {
                const res = await client.query({
                    query: GET_ASSET_DETAIL,
                    variables: {
                        getAssetDetailsAssetId: assetId,
                        getAssetDetailsAssetGroup: assetGroup,
                    },
                    fetchPolicy:'network-only'
                });
                log.success(MODULE_NAME, "getAsset: ", res);
                if (res?.data?.getAssetDetails?.tabsItems) {
                    try {
                        // Parse a JSON
                        res.data.getAssetDetails.tabsItems = JSON.parse(res?.data?.getAssetDetails?.tabsItems);
                    } catch (e) {
                        // You can read e for more info
                    }
                }
                return res?.data?.getAssetDetails;
            }
        } catch (err) {
        }
    }
}
