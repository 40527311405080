import { LoggerModule } from "@vlinder-web/logger-module-react";
import { VApplication } from "../../../application";
import { LIST_ASSETS } from "../graphql/list-assets.query";
import { GraphqlServer } from "../../../servers/graphql.server";

const MODULE_NAME = "AssetsService";
const log: any = LoggerModule.getInstance();

export class AssetsService {
    private static instance: AssetsService;
    private vapp: VApplication;
    private constructor(props: any = {}) {
        this.vapp = VApplication.getInstance();
    }
    public static getInstance(props: any = {}): AssetsService {
        if (!AssetsService.instance) {
            AssetsService.instance = new AssetsService(props);
        }
        return AssetsService.instance;
    }

    async getAssets(size: number, offset: number) {
        const client = GraphqlServer.getInstance()?.client;
        try {
            if (client) {
                const res = await client.query({
                    query: LIST_ASSETS,
                    variables: {
                        listAssetsSize: size,
                        listAssetsOffset: offset,
                    },
                });
                log.success(MODULE_NAME, "getAssets: ", res);
                return res?.data?.listAssets;
            }
        } catch (err) {
            log.error(MODULE_NAME, "error in get assets", err);
            // throw Error(`${err?.message}`)
            return {
                pagination: {
                    nextOffset: 0,
                    totalItems: 0,
                },
                results: [],
            };
        }
    }
}
