export const palette = {
    purpleLight: "#8C6FF7",
    purplePrimary: "#5A31F4",
    purpleDark: "#3F22AB",

    greenLight: "#56DCBA",
    greenPrimary: "#0ECD9D",
    greenDark: "#0A906E",

    black: "#0B0B0B",
    white: "#ffffff",
    grey: "#e3e6eb",
    greyDark: "#d9d9d9",
    silver: "#c3c3c3",
    "grey-2": "#606060",
    blackTransparent: "rgba(11,11,11,0.3)",

    "blue-2": "#1a4677",
    blueSecondary: "#5987af",
    blueDark: "#88aecb",
    blueLight: "#bad5ea",

    orangePrimary: "#e96830",
    bluePrimary: "#242c43",
    blueBCCI: "#2664f5",
    transparent: "transparent",
};
