import { LoggerModule } from "@vlinder-web/logger-module-react";
import {
    WalletconnectModule,
    WALLETCONNECT_SESSION_UPDATE,
    WALLETCONNECT_CONNECT,
    WALLETCONNECT_DISCONNECT,
} from "@vlinder-web/walletconnect-module-react";
import { VApplication } from "../application/index";

const MODULE_NAME = "WalletConnectClientService";
const log: any = LoggerModule.getInstance();

export class WalletConnectClientService {
    private static instance: WalletConnectClientService;
    private walletConnectModule: WalletconnectModule;
    private eventbus: any;

    private constructor(props: any = {}) {
        const vapp = VApplication.getInstance();
        this.walletConnectModule = vapp.getSync("services.WalletconnectModule");
        this.eventbus = vapp?.getSync("services.EventBusModule")?.getEventBus();
    }
    public static getInstance(props: any = {}): WalletConnectClientService {
        if (!WalletConnectClientService.instance) {
            WalletConnectClientService.instance =
                new WalletConnectClientService(props);
        }
        return WalletConnectClientService.instance;
    }

    async init() {
        this.eventbus.on(WALLETCONNECT_CONNECT, function (payload: any) {
            log.success(MODULE_NAME, "Event Received: ", WALLETCONNECT_CONNECT);
            log.success(MODULE_NAME, "payload: ", payload);
        });

        this.eventbus.on(WALLETCONNECT_SESSION_UPDATE, function (payload: any) {
            log.success(
                MODULE_NAME,
                "Event Received: ",
                WALLETCONNECT_SESSION_UPDATE
            );
            log.success(MODULE_NAME, "payload: ", payload);
        });

        this.eventbus.on(WALLETCONNECT_DISCONNECT, function (payload: any) {
            log.success(
                MODULE_NAME,
                "Event Received: ",
                WALLETCONNECT_DISCONNECT
            );
            log.success(MODULE_NAME, "payload: ", payload);
        });
    }
}
