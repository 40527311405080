import { LoggerModule } from "@vlinder-web/logger-module-react";

import { DATA } from "./config/myassets.data";

const MODULE_NAME = "MyAssetsService";
const log = LoggerModule.getInstance();

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export class MyAssetsService {
    private static instance: MyAssetsService;

    private constructor(props: any = {}) {}
    public static getInstance(props: any = {}): MyAssetsService {
        if (!MyAssetsService.instance) {
            MyAssetsService.instance = new MyAssetsService(props);
        }
        return MyAssetsService.instance;
    }

    async getAssets(size: number, offset: number) {
        let _found: any = DATA;
        _found = _found.slice(offset, offset + size);
        return {
            pagination: {
                nextOffset: offset + _found.length,
                totalItems: DATA?.length,
            },
            results: _found,
        };
        return _found;
    }
}
