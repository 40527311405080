import { createTheming } from "@callstack/react-theme-provider";

import { palette } from "./color.palette";

export const themeConfig = {
    colors: {
        mainBackground: palette?.grey,
        mainForeground: palette?.black,

        toastSuccess: palette?.greenDark,
        toastError: palette?.orangePrimary,

        primary: palette?.blueBCCI,
        secondary: palette?.orangePrimary,

        headerBorderColor: palette?.greyDark,

        primaryCardBackground: palette?.blueBCCI,
        secondaryCardBackground: palette?.white,

        primaryButtonBackground: palette?.orangePrimary,

        primaryCardText: palette.white,
        secondaryCardText: palette.blueBCCI,

        primaryText: palette.blueBCCI,
        secondaryText: palette.white,

        ...palette,
    },
};

const { ThemeProvider, withTheme, useTheme } = createTheming(themeConfig);

export { ThemeProvider, withTheme, useTheme };
