import gql from "graphql-tag";
export const GET_ASSET_DETAIL = gql`
    query (
        $getAssetDetailsAssetId: String
        $getAssetDetailsAssetGroup: String
    ) {
        getAssetDetails(
            assetId: $getAssetDetailsAssetId
            assetGroup: $getAssetDetailsAssetGroup
        ) {
            img
            assetGroup
            assetId
            title
            description
            price
            symbol
            altPrice
            altPrice
            variants {
                images
                thumbnail
            }
            meta {
                key
                value
            }
            secondaryMeta {
                key
                value
            }
            tabsItems 
            altSymbol
            buttons {
                key
                title
            }
        }
    }
`;
