// @ts-nocheck
import React, { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";
import { TypeWriterLoaderVW } from "@vlinder-web/typewriter-loader-widget-react";
import { Root } from "../framework/BugsBunny/src/index";
const { isAuthenticated } = {};

const ProfilePage = lazy(() => import("../pages/Profile"));
const LandingPage = lazy(() => import("../pages/Landing"));
const LoginPage = lazy(() => import("../pages/Login"));
const MyAssetsPage = lazy(() => import("../pages/MyAssets"));

const RouterConfig = (props) => (
    <Switch>
        <Route path="/assets" component={Root} />
        {/* <Route path="/myassets" component={Root} /> */}
        <Route path="/profile" component={ProfilePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/" component={LandingPage} />
    </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: `/login`,
                    }}
                />
            )
        }
    />
);

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={(props) => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

export const AppNavigator = (): JSX.Element => {
    return (
        <Router>
            <Suspense fallback={<TypeWriterLoaderVW />}>
                <RouterConfig />
            </Suspense>
        </Router>
    );
};
