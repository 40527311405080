import _ from "lodash";

import { LoggerModule } from "@vlinder-web/logger-module-react";

const is = require("is_js");

const MODULE_NAME = "BaseApplication";
const log: any = LoggerModule?.getInstance({
    options: {
        colorize: !process.env.NO_COLOR,
        visible:
            process.env.NODE_ENV === "development" || process.env.SHOW_LOGS,
        //@ts-ignore
        browser: process.browser,
    },
});

export class BaseApplication {
    private services: {
        key: string;
        service: any;
    }[] = [];

    private configs: {
        key: string;
        config: any;
    }[] = [];

    constructor(options: any) {}

    service(key: string, service: any) {
        if (is.existy(key) && is.existy(service)) {
            if (_.find(this.services, { key: key })) {
                log.warning(`${key} already found. Service not registered`);
            } else {
                this.services.push({
                    key: key,
                    service: service,
                });
            }
        }
    }

    getSync(key: string) {
        if (is.existy(key)) {
            const found: any = _.find(this.services, { key: key });
            const configFound: any = _.find(this.configs, { key: key });
            if (found && found?.service) {
                let obj: any;
                if (configFound?.config) {
                    obj = found.service.getInstance(configFound.config);
                } else {
                    obj = found.service.getInstance();
                }
                return obj;
            }
        }
    }

    configure(key: string, value: any) {
        if (is.existy(key)) {
            if (_.find(this.configs, { key: key })) {
                log.warning(`${key} already found. Service not configured`);
            } else {
                this.configs.push({
                    key: key,
                    config: value,
                });
            }
        }
    }
}
