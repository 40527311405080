import React from "react";
import { Toaster } from "react-hot-toast";
import { useTheme } from "../providers/theme";
export const Accessories = () => {
    const theme = useTheme();
    return (
        <>
            <Toaster
                toastOptions={{
                    className: "",
                    duration: 8000,
                    style: {
                        borderLeft: `4px solid ${theme?.colors?.primary}`,
                        padding: "16px",
                        color: theme?.colors?.black,
                        fontSize: 15,
                        fontWeight: "bolder",
                    },
                    success: {
                        style: {
                            borderColor: theme?.colors?.toastSuccess,
                        },
                    },
                    error: {
                        style: {
                            borderColor: theme?.colors?.toastError,
                        },
                    },
                }}
            />
        </>
    );
};
