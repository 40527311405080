import { MACHINE_CONFIG } from "./machine-test/config";
import { MACHINE_SERVICES } from "./machine-test/service";
export class UnitTest {
    private app: any;
    constructor(app: any) {
        this.app = app.getInstance();
    }

    async run() {
        //------------->>>>>--------------//
        const ebModule = this.app.getSync("services.EventBusModule");
        const eb = ebModule?.getEventBus();
        eb.on("test-event", function (payload: any) {
            console.log("event received", payload);
        });
        eb.emit("test-event", null, {
            message: "Hello From vlinder unit test",
        });
        //------------->>>>>--------------//

        //------------->>>>>--------------//
        const smfModule = this.app.getSync("services.FSMFactoryModule");
        await smfModule?.createFSM(MACHINE_CONFIG, MACHINE_SERVICES);
        await smfModule?.transitTo(MACHINE_CONFIG.id, "TRIGGER_GREEN");
        //------------->>>>>--------------//
    }
}
