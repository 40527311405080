import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ViewportProvider } from "@vlinder-web/viewport-provider-react";
import { AppNavigator } from "../AppNavigator";

import { ThemeProvider } from "../providers/theme";
import { Accessories } from "./Accessories";

import { GraphqlServer } from "../servers/graphql.server";

import { DEFAULT_BLUEPRINT_CONFIG } from "../config/default.blueprint";

export const BootstrapApp = () => {
    const options = {
        httpUri: DEFAULT_BLUEPRINT_CONFIG.servers.graphql.appUrl,
    };
    return (
        <>
            <ApolloProvider client={GraphqlServer.getInstance(options)?.client}>
                <ThemeProvider>
                    <ViewportProvider>
                        <AppNavigator />
                    </ViewportProvider>
                </ThemeProvider>
                <Accessories />
            </ApolloProvider>
        </>
    );
};
