import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { DATA } from "./config/myasset.data";

const MODULE_NAME = "MyAssetService";
const log: any = LoggerModule.getInstance();

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export class MyAssetService {
    private static instance: MyAssetService;

    private constructor(props: any = {}) {}
    public static getInstance(props: any = {}): MyAssetService {
        if (!MyAssetService.instance) {
            MyAssetService.instance = new MyAssetService(props);
        }
        return MyAssetService.instance;
    }

    async getAsset(assetId: string, assetGroup: string) {
        const _found = _.find(DATA, {
            assetId: assetId,
            assetGroup: assetGroup,
        });
        return _found;
    }
}
