const DUMMY_IMAGE = "https://i.ibb.co/nr6SHqy/bbb-splash.png";
export const DATA = [{
    img: DUMMY_IMAGE,
    assetGroup: "1",
    assetId: "1",
  variants: [
    {
      images: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      thumbnail:DUMMY_IMAGE
    },
    {
      images: DUMMY_IMAGE,
    },
  ],
  title: "2011 World Cup",
  price: "5,00,000",
  symbol: "₹",
  altSymbol: "Ξ",
  altPrice: "2.32",
  description:
    " India won the tournament, defeating Sri Lanka by 6 wickets in the final at Wankhede Stadium in Mumbai, thus becoming the first country to win the Cricket World Cup final on home soil.",
  meta: [
    {
      value: "10th Cricket World Cup",
      key: "Edition",
    },
    {
      value: "19 February - 2 April 2011",
      key: "Date"
    },
    {
      key: "Cricket Format",
      value: "One Day International"
    },
     {
      key: "Tournament Formats",
      value: "Round-robin and Knockout",
    },
    {
      value: "India, Srilanka and Bangladesh",
      key: "Host(s)",
    },
    {
      value: "14 (from 104 entrants)",
      key: "Participants",
    },
     {
      value: "49",
      key: "Matches played",
    },
  ],
  secondaryMeta: null,
  buttons: [
    {
    key: "view",
    title: "View",
  },
  // {
  //   key: "buyNowFiat",
  //   title: "Buy In INR",
  // },
  // {
  //   key: "buyNowCrypto",
  //   title: "Buy In Ethers",
  // },
  ],
  tabsItems: [
     {
    title: "History",
    description: "",
    timelineData: [
        {
            value: {
                event: "Minted",
                from: "0x5584awqeqweqwewdqw",
                fromHref: "https://www.google.com",
                to: "0x5474b3wtgewsfware52",
                toHref: "https://www.google.com",
                price: "₹5,00,000",
                date: "09-08-2021",
                dateHref: "https://www.google.com",
            },
            props: {
                label: "09-08-2021",
            },
        },
        {
            value: {
                event: "Listed",
                from: "0x5584aweftwerfwerfwefwaeft",
                fromHref: "https://www.google.com",
                to: "0x5474bwerfwerfwefwefwefwefwef",
                toHref: "https://www.google.com",
                price: "$5000",
                date: "01-08-2021",
                dateHref: "https://www.google.com",
            },
            props: {
                label: "01-08-2021",
            },
        },
    ]
  },
  ]
},{
    img: DUMMY_IMAGE,
    assetGroup: "2",
    assetId: "2",
  variants: [
    {
      images: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      thumbnail:DUMMY_IMAGE
    },
    {
      images: DUMMY_IMAGE,
    },
  ],
  title: "Asset 2",
  price: "2,50,000",
  symbol: "₹",
  altSymbol: "Ξ",
  altPrice: "1.32",
  description:
    "",
  meta: [
    {
      value: "2",
      key: "Token Id",
    }
  ],
  secondaryMeta: null,
  buttons: [
    {
    key: "view",
    title: "View",
  },
  // {
  //   key: "buyNowFiat",
  //   title: "Buy In INR",
  // },
  // {
  //   key: "buyNowCrypto",
  //   title: "Buy In Ethers",
  // },
  ],
  tabsItems: [
     {
    title: "History",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  ]
},
    {
    img: DUMMY_IMAGE,
    assetGroup: "3",
    assetId: "3",
  variants: [
    {
      images: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      thumbnail:DUMMY_IMAGE
    },
    {
      images: DUMMY_IMAGE,
    },
  ],
  title: "2011 World Cup",
  price: "5,00,000",
  symbol: "₹",
  altSymbol: "Ξ",
  altPrice: "2.32",
  description:
    "",
  meta: [
    {
      value: "3",
      key: "Token Id",
    }
  ],
  secondaryMeta: null,
  buttons: [
    {
    key: "view",
    title: "View",
  },
  // {
  //   key: "buyNowFiat",
  //   title: "Buy In INR",
  // },
  // {
  //   key: "buyNowCrypto",
  //   title: "Buy In Ethers",
  // },
  ],
  tabsItems: [
     {
    title: "History",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  ]
}
];