export const VLINDER_LOGO = require("./res/images/vLogo.png");
export const KLEFKI_LOGO = require("./res/images/klefki.png");

export const BCCI_1_IMAGE = require("./res/images/bcci_1.png");
export const BCCI_2_IMAGE = require("./res/images/bcci_2.png");
export const BCCI_3_IMAGE = require("./res/images/bcci_3.png");
export const BCCI_4_IMAGE = require("./res/images/bcci_4.png");
export const BCCI_LOGO = require("./res/images/bcci_logo.png");
export const DUMMY_IMAGE = require("./res/images/300-300.png");
export const EMPTY_IMAGE = require("./res/images/empty.png");
export const ORDINARY_DAY_IMAGE = require("./res/images/ordinary-day.png");
export const VLINDER_IMAGE = require("./res/images/vlinder-logo.png");

export const IMAGES = [
    VLINDER_LOGO,
    KLEFKI_LOGO,
    BCCI_1_IMAGE,
    BCCI_2_IMAGE,
    BCCI_3_IMAGE,
    BCCI_4_IMAGE,
    BCCI_LOGO,
    DUMMY_IMAGE,
    EMPTY_IMAGE,
    ORDINARY_DAY_IMAGE,
    VLINDER_IMAGE,
];
