import { EventBusModule } from "@vlinder-web/event-bus-module-react";
import { FSMFactoryModule } from "@vlinder-web/state-machine-factory-module-react";
import { WalletconnectModule } from "@vlinder-web/walletconnect-module-react";

import { BaseApplication } from "./base/baseApplication";
import { UnitTest } from "./unit.test";

import { DEFAULT_BLUEPRINT_CONFIG } from "../config/default.blueprint";
import { AssetsService } from "../pages/Assets/services";
import { AssetDetailService } from "../pages/AssetDetail/services";
import {
    WalletConnectClientService,
    MyAssetService,
    MyAssetsService,
    WalletApiService,
} from "../services";
import { GraphqlServer } from "../servers/graphql.server";
export class VApplication extends BaseApplication {
    private static instance: VApplication;

    private constructor(options: any = {}) {
        super(options);

        this.setupBindings();

        //Modules
        this.initModules();

        //Services
        this.initServices();
    }

    static getInstance(options: any = {}) {
        if (!this.instance) {
            this.instance = new VApplication(options);
        }
        return this.instance;
    }

    // @ts-ignore
    async start() {
        await this.initRunLevel();
        await this.unitTest();
    }

    setupBindings() {
        //------------->>>>>--------------//
        this.configure("services.WalletconnectModule", {
            bridge: DEFAULT_BLUEPRINT_CONFIG.walletConnect?.relayURL,
        });
        //------------->>>>>--------------//
    }

    async initRunLevel() {
        //------------->>>>>--------------//
        const wcClientService = this.getSync(
            "services.WalletConnectClientService"
        ) as WalletConnectClientService;
        await wcClientService.init();
        //------------->>>>>--------------//
    }

    async unitTest() {
        const ut = new UnitTest(VApplication);
        ut.run();
    }

    initModules() {
        this.service("services.FSMFactoryModule", FSMFactoryModule);
        this.service("services.EventBusModule", EventBusModule);
        this.service("services.WalletconnectModule", WalletconnectModule);
    }

    initServices() {
        this.service("services.AssetsService", AssetsService);
        this.service("services.AssetDetailService", AssetDetailService);
        this.service(
            "services.WalletConnectClientService",
            WalletConnectClientService
        );
        this.service("services.MyAssetService", MyAssetService);
        this.service("services.MyAssetsService", MyAssetsService);
        this.service("services.WalletApiService", WalletApiService);
    }

    getGraphQLClient() {
        return GraphqlServer?.getInstance()?.client;
    }
}
