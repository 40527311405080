import AppLoading from "expo-app-loading";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import { useState } from "react";

export interface IApploader {
    assets: any[];
    fonts: any;
}

export const useAppLoader = ({ assets, fonts }: IApploader) => {
    const [isReady, setReady] = useState<boolean>(false);

    const cacheResource = async () => {
        await downloadAssets();
        await downloadFonts();
    };

    const downloadAssets = async () => {
        if (assets && Array.isArray(assets)) {
            assets.map(async (asset) => {
                await Asset.loadAsync(asset);
            });
        }
    };

    const downloadFonts = async () => {
        if (fonts) {
            await Font.loadAsync(fonts);
        }
    };

    return {
        AppLoading,
        cacheResource,
        isReady,
        setReady,
    };
};
