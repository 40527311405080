import gql from "graphql-tag";

export const LIST_ASSETS = gql`
    query Query($listAssetsSize: Int, $listAssetsOffset: Int) {
        listAssets(size: $listAssetsSize, offset: $listAssetsOffset) {
            pagination {
                nextOffset
                totalItems
            }
            results {
                item {
                    assetGroup
                    assetId
                    name
                    img
                    category
                    price
                    symbol
                    secondaryPrice
                    secondarySymbol
                    sold
                }
            }
        }
    }
`;
