let DATA = [
    {
        item: {
            idx: 1,
            assetGroup: "1",
            assetId: "1",
            name: "2011 World Cup Champions",
            img: require("./assets/champ.jpeg"),
            category: "Bronze",
            price: "5,00,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 2.3,

            note: " 28-12-2020",
        },
    },
    {
        item: {
            idx: 2,
            assetGroup: "2",
            assetId: "2",
            name: "Two Captains",
            img: require("./assets/cup.jpeg"),
            price: "2,50,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 1.12,
            note: "28-2-2021",
        },
    },

    {
        item: {
            idx: 4,
            assetGroup: "4",
            assetId: "4",
            name: "Sachin with India Flag",
            img: require("./assets/sachin.jpeg"),
            price: "80,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 0.36,
            note: "28-2-2021",
        },
    },
    {
        item: {
            idx: 5,
            assetGroup: "5",
            assetId: "5",
            name: "Yuvi - The Warrior",
            img: require("./assets/yuvi.jpeg"),
            price: "92,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 0.41,

            note: "28-2-2021",
        },
    },

    {
        item: {
            idx: 6,
            assetGroup: "6",
            assetId: "6",
            name: "2011 World Cup Champions",
            img: require("./assets/champ.jpeg"),
            category: "Bronze",
            price: "5,00,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 2.3,
            note: "28-2-2021",
        },
    },
    {
        item: {
            idx: 7,
            assetGroup: "7",
            assetId: "7",
            name: "Two Captains",
            img: require("./assets/cup.jpeg"),
            price: "2,50,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 1.12,

            note: "28-2-2021",
        },
    },
    {
        item: {
            idx: 9,
            assetGroup: "9",
            assetId: "9",
            name: "Sachin with India Flag",
            img: require("./assets/sachin.jpeg"),
            price: "80,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 0.36,
            note: "28-2-2021",
        },
    },
    {
        item: {
            idx: 10,
            assetGroup: "10",
            assetId: "10",
            name: "Yuvi - The Warrior",
            img: require("./assets/yuvi.jpeg"),
            price: "92,000",
            symbol: "₹",
            secondarySymbol: "Ξ",
            secondaryPrice: 0.41,
            note: "28-2-2021",
        },
    },
];

export { DATA };
