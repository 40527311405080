import React, { lazy } from "react";

const AssetDetailPage = lazy(() => import("../../../../pages/AssetDetail"));
const AssetsPage = lazy(() => import("../../../../pages/Assets"));

const MyAssetsPage = lazy(() => import("../../../../pages/MyAssets"));
const MyAssetDetailPage = lazy(() => import("../../../../pages/MyAssetDetail"));

const MyWalletPage = lazy(() => import("../../../../pages/MyWallet"));

export const routes = [
    { path: "/", Component: AssetsPage },
    { path: "/asset", Component: AssetDetailPage },
    { path: "/my-assets", Component: MyAssetsPage },
    { path: "/my-assets/asset", Component: MyAssetDetailPage },
    { path: "/my-wallet", Component: MyWalletPage },
];
