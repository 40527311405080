export const FONTS = {
    "SFProText-Bold": require("./res/fonts/SF-Pro-Text-Bold.otf"),
    "SFProText-Semibold": require("./res/fonts/SF-Pro-Text-Semibold.otf"),
    "SFProText-Regular": require("./res/fonts/SF-Pro-Text-Regular.otf"),
    "conthrax-sb": require("./res/fonts/conthrax-sb.ttf"),
    "Ubuntu-Bold": require("./res/fonts/Ubuntu-Bold.ttf"),
    "Ubuntu-Light": require("./res/fonts/Ubuntu-Light.ttf"),
    "Ubuntu-Medium": require("./res/fonts/Ubuntu-Medium.ttf"),
    "Ubuntu-Regular": require("./res/fonts/Ubuntu-Regular.ttf"),
};
