import React from "react";

import { LoggerModule } from "@vlinder-web/logger-module-react";

import { VApplication } from "./application";
import { FONTS, IMAGES } from "./assets";
import { BootstrapApp } from "./bootstrap";
import { useAppLoader, useConstructor } from "./providers";

const MODULE_NAME = "App";
const log: any = LoggerModule?.getInstance({
    visible: false,
});

const _bootstrapHandler = async () => {
    const vapp = VApplication.getInstance();
    await vapp.start();
    log?.success(MODULE_NAME, `🚀 Main Application Started 🚀`);

    //Do other config here !! Example: Sentry Initialization !!
};

export const App = () => {
    useConstructor(_bootstrapHandler);
    const { AppLoading, setReady, isReady, cacheResource } = useAppLoader({
        fonts: FONTS,
        assets: [...(IMAGES || [])],
    });

    if (!isReady && AppLoading) {
        return (
            <AppLoading
                startAsync={cacheResource}
                onFinish={() => setReady(true)}
                onError={log?.warn}
            />
        );
    }
    return <BootstrapApp />;
};
