import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from } from "apollo-link";
import { HttpLink } from "apollo-link-http";

const is = require("is_js");

export interface IConfig {
    httpUri?: string;
    token?: string | null;
}

const getAuthToken = () => {
    // const authService = AuthService.getInstance();
    // const _token: any = authService.authTokenDS.get(AUTH_TOKEN);
    // console.log(`_token in graphql server :`, _token);
    // if (_token) {
    //     return _token.value;
    // }
    return null;
};

export class GraphqlServer {
    readonly httpLink: any;
    readonly wsLink: any;
    link: any;
    splitLink: any;
    readonly authMiddleware: any;
    readonly client: any;
    static instance: GraphqlServer;
    public httpURI;
    public token;

    private constructor(httpURI: any, token: string | null = null) {
        this.httpURI = httpURI;
        this.token = token;
        console.log("httpURI", this.httpURI);
        if (this.httpURI) {
            this.httpLink = new HttpLink({
                uri: this.httpURI,
            });
        }

        this.authMiddleware = new ApolloLink((operation: any, forward: any) => {
            // add the authorization to the headers
            const token = getAuthToken();
            operation.setContext({
                headers: {
                    // authorization: token || null,
                    authentication: token || null,
                },
            });

            return forward(operation);
        });
        this.link = from([this.authMiddleware, this.httpLink]);

        this.client = new ApolloClient({
            link: this.link,
            cache: new InMemoryCache(),
        });
    }

    static getInstance(options?: any): GraphqlServer {
        if (is.not.existy(options)) {
            options = {};
        }
        if (!GraphqlServer.instance) {
            GraphqlServer.instance = new GraphqlServer(
                options?.httpUri,
                options?.token
            );
        }
        return GraphqlServer.instance;
    }
}
