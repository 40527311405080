//@ts-nocheck
import React, { Fragment } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import HeaderSM from "./component/common/header/headerSM";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import { useGetNavitem } from "./appProvider";
import {
    useViewport,
    ViewportProvider,
} from "@vlinder-web/viewport-provider-react";

const MENU_ITEMS = [
    {
        title: "Home",
        icon: <i className="icon-home"></i>,
        path: "/home",
        type: "sub",
        active: true,
        bookmark: true,
        children: [{ title: "Home", type: "sub" }],
    },
    {
        title: "Gallery",
        icon: <i className="pe-7s-photo-gallery"></i>,
        path: "/gallery",
        type: "sub",
        active: false,
        bookmark: true,
        children: [
            { title: "Gallery", type: "sub" },
            {
                title: "Flash Cards",
                type: "link",
                path: `/gallery/flash-cards`,
            },
            {
                title: "Coins",
                type: "link",
                path: `/gallery/coins`,
            },
            {
                title: "Assets",
                type: "link",
                path: `/gallery/assets`,
            },
        ],
    },
    {
        title: "Moments",
        icon: <i className="pe-7s-diamond"></i>,
        path: "/moments",
        type: "sub",
        active: false,
        bookmark: true,
        children: [
            { title: "Moments", type: "sub" },
            {
                title: "Test Centuries",
                type: "link",
                path: `/moments/test-centuries`,
            },
            {
                title: "ODI Centuries",
                type: "link",
                path: `/moments/odi-centuries`,
            },
        ],
    },
    {
        title: "Wallet",
        icon: <i className="pe-7s-wallet"></i>,
        path: "/wallet",
        type: "sub",
        active: false,
        bookmark: true,
        children: [
            { title: "Wallet", type: "sub" },
            {
                title: "My Account",
                type: "link",
                path: `/wallet/my-account`,
            },
            {
                title: "My Transactions",
                type: "link",
                path: `/wallet/my-transactions`,
            },
        ],
    },

    {
        title: "FAQs",
        icon: <i className="pe-7s-help1"></i>,
        path: "/faq",
        type: "sub",
        active: false,
        bookmark: true,
        children: [{ title: "FAQs", type: "sub" }],
    },
];

const App = ({ children }) => {
    const { bp, width } = useViewport();

    const getHeader = () => {
        if (bp === "xs" || bp == "sm") {
            return <HeaderSM menuItems={MENU_ITEMS} searchbar={false} />;
        } else {
            return <Header menuItems={MENU_ITEMS} searchbar={false} />;
        }
    };
    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    {getHeader()}
                    <Sidebar menuItems={MENU_ITEMS} />
                    <Rightsidebar />
                    <div className="page-body">{children}</div>
                    <Footer />
                    {/* <ThemeCustomize/> */}
                </div>
            </div>
        </Fragment>
    );
};

export default App;
