import axios, { AxiosInstance } from "axios";
import { IAssetData, IGasPrices, IParsedTx } from "./types/walletapi.types";

const api: AxiosInstance = axios.create({
    baseURL: "https://ethereum-api.xyz",
    timeout: 30000, // 30 secs
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export class WalletApiService {
    private static instance: WalletApiService;

    private constructor(props: any = {}) {}
    public static getInstance(props: any = {}): WalletApiService {
        if (!WalletApiService.instance) {
            WalletApiService.instance = new WalletApiService(props);
        }
        return WalletApiService.instance;
    }

    async getAccountAssets(
        address: string,
        chainId: number
    ): Promise<IAssetData[]> {
        const response = await api.get(
            `/account-assets?address=${address}&chainId=${chainId}`
        );
        const { result } = response.data;
        return result;
    }

    async getAccountTransactions(
        address: string,
        chainId: number
    ): Promise<IParsedTx[]> {
        const response = await api.get(
            `/account-transactions?address=${address}&chainId=${chainId}`
        );
        const { result } = response.data;
        return result;
    }

    async getAccountNonce(address: string, chainId: number): Promise<string> {
        const response = await api.get(
            `/account-nonce?address=${address}&chainId=${chainId}`
        );
        const { result } = response.data;
        return result;
    }

    async getGasPrice() {
        const response = await api.get(`/gas-prices`);
        const { result } = response.data;
        return result;
    }

    async getAccountBalance(address: string, chainId: number) {
        const response = await api.get(
            `/account-balance?address=${address}&chainId=${chainId}`
        );
        const { result } = response.data;
        return result;
    }
}
